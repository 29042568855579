export default class Modal {
    constructor(el){
        this.el = el
        this.images = this.el.querySelectorAll('.js-modal')
        this.modal_item = document.querySelector('.modal')
        this.modal_image = this.modal_item.querySelector('img')
        this.close_btn = this.modal_item.querySelector('i')
        this.thumb_images = Array.from(this.modal_item.querySelectorAll('.modal_image_thumb'))
        this.is_open = false
        this.active = 0
        this.src = ""
    }
    init(){
        this.images.forEach((img,i) => {
            img.addEventListener('click', () => {
                this.open()
                this.setSrc(i)
            })
        })
 
        this.close_btn.addEventListener('click', this.close.bind(this))
        this.thumb_images.forEach((img,i) => img.addEventListener('click', () => {
            this.setSrc(i)
        }))
    }
    setSrc(index){
        this.src = this.images[this.active].src
        this.modal_image.src = this.src
        this.thumb_images[index].classList.add('active')
        this.setActive(index)
    }
    setActive(index) {
        this.active = index
        this.thumb_images.forEach((img,i) => i == this.active ? img.classList.add('active') : img.classList.remove('active'))
    }
    open(){
        this.is_open = true
        this.modal_item.classList.add('active')
        this.modal_item.focus()
        this.keyEvents()
    }
    close(){
        this.is_open = false
        this.modal_item.classList.remove('active')
        this.thumb_images.forEach(img => img.classList.remove('active'))
        this.active = 0
    }
    keyEvents() {
        window.addEventListener('keydown', (event) => {
            //Esc button
            if (event.key === "Escape") {
                this.close()
            }
            //Left button
            if (event.key === "ArrowLeft") {
                if (this.active == 0) {
                    this.active = this.thumb_images.length - 1
                } else {
                    this.active --
                }
            }
            //Right button
            if (event.key === "ArrowRight") {
                if (this.active == this.thumb_images.length - 1) {
                    this.active = 0
                } else {
                    this.active ++
                }                    
            }
            this.setSrc(this.active)                             
        })
    }
}