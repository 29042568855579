import React from 'react'
import {useState, useEffect} from 'react'

const LendingSim = (props) => {
    const [tax] = useState((parseInt(props.tax) / 100) / 12)
    const [time] = useState(365)
    const [years, setYears] = useState(5)
    const [value, setValue] = useState(10000)
    const [total, setTotal] = useState(value + (value * tax * time * years) / 36500)
    const [instalment, setInstalment] = useState(value * ( tax / (1 - (1 + tax)**(-(years * 12)))))

    const handleChange = (e) => {
        let val = e.target.value
        setValue(val)
        setTotal(parseInt(val) + (parseInt(val) * tax * time * 5) / 36500)
        setInstalment(parseInt(val) * ( tax / (1 - (1 + tax)**(-(years * 12)))))
    }
    const handleYears = (e) => {
        let val = e.target.value
        setYears(val)
        setInstalment(total * ( tax / (1 - (1 + tax)**(-(val * 12)))))
    }
    return (
        <div className="py-8 px-4 lg:p-8 bg-gray-100">
            <div className="mb-8">
                <h1 className="text-bold text-center text-3xl">Simulazione finanziamento</h1>
            </div>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-3">
                <div className="shadow p-8 rounded-2xl border-gray-300 bg-white">
                    <h1 className="text-gray-500">Importo del veicolo da acquistare</h1>
                    <span className="mr-1 text-2xl">€</span>
                    <input className="text-2xl py-1 cursor-pointer" type="number" defaultValue={value} name="import" min="5000" max="30000" step="1000" onChange={handleChange} />
                </div>
                <div className="shadow p-8 rounded-2xl border-gray-500	bg-white">
                    <h1 className="text-gray-500">Anni</h1>
                    <input className="text-2xl py-1 cursor-pointer" type="number" min="1" max="10" defaultValue={years} onChange={handleYears} />
                </div>     
                <div className="shadow p-8 rounded-2xl border-gray-500	bg-white">
                    <h1 className="text-gray-500">Tasso di Interesse (TAN)</h1>
                    <p className="text-2xl py-1">{tax * 100 * 12 + "%"}</p>
                </div>                        
            </div>
            <div className="w-full lg:w-1/2 mx-auto">
                <div className="my-12 py-12 bg-black rounded-2xl">
                    <h1 className="text-center text-2xl text-white">Importo rata</h1>
                    <p className="text-center text-2xl text-white">{instalment.toFixed(2)} €</p> 
                </div>
                <div className="flex justify-center">
                    <a href="/contatti" className="text-center w-full md:w-3/12 mx-auto bg-black text-white shadow bg-teal-400 hover:bg-gray-600 focus:shadow-outline focus:outline-none font-bold py-2 px-4 rounded text-1xl">Contattaci</a>
                </div>
                <p className="text-gray-300 text-sm mt-8"><span className="align-top">*</span>   La simulazione è a solo scopo illustrativo e non comporta alcun obbligo contrattuale, l'importo della rata potrebbe differire in fase di istruttoria finanziamento.</p>
            </div>
        </div>
    )
}
export default LendingSim;