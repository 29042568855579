const HpVideo = (hp_video) => {
    const video_m_url = hp_video.dataset.videom
    const video_url = hp_video.dataset.videod
    const source = hp_video.querySelector('source')
    window.addEventListener('resize', () => getSourceVideo)
    getSourceVideo()
    function getSourceVideo() {
        if (window.innerWidth < 768) {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            hp_video.style.setProperty('--vh', `${vh}px`);                
            source.setAttribute('src',video_m_url)
        } else {
            source.setAttribute('src',video_url)
        
        }
        hp_video.load()
        hp_video.play()
    }
}
export default HpVideo