export default class ContactForm{
    constructor(el) {
        this.el = el
        this.checkbox = el.querySelector('input[type=checkbox]')
        this.submit = el.querySelector('input[type=submit]')
        this.init()
    }
    init() {
        this.checkbox.addEventListener('change', () => {
            if (this.checkbox.checked) {
                this.submit.classList.remove("cursor-not-allowed", "opacity-10")
            } else {
                this.submit.classList.add("cursor-not-allowed", "opacity-10")
            }
        })
    }

}