import verge from 'verge'
import gsap from 'gsap'
export default class Stagger{
    constructor(el){
        this.el = el
    }
    init(){
        this.setOpacity()
        window.addEventListener('scroll', this.animate.bind(this))
        this.animate()
    }
    setOpacity(){
        gsap.set(this.el,{
            opacity: 0,
            y: 50
        })
    }
    animate(){
        if (verge.inY(this.el)){
            gsap.to(this.el,{
                y:0,
                opacity: 1,
                duration: 1
            })
        }
    }
}