import '../../scss/application.scss'
import '../tailwind.scss'
import Modal from '../components/modal'
import Stagger from '../components/stagger'
import React from 'react'
import ReactDOM from 'react-dom'
import LendingSim from '../components/lending_sim'
import Menu from '../components/menu'
import Siema from 'siema'
import ContactForm from '../components/contact_form'
import PageTransition from '../components/page_transition'
import HpVideo from '../components/hp_video'

document.addEventListener('DOMContentLoaded', () => {
    






    const hp_v = document.querySelector('.js-video-media-q')
    if (hp_v) {
        HpVideo(hp_v)
    }
    const contact_form = document.querySelector('.js-contact-form')
    if (contact_form) {
        new ContactForm(contact_form)
    }
    const modal_imgs = document.querySelector('.js-modal-images')
    if (modal_imgs) {
        const modal = new Modal(modal_imgs)
        modal.init()
    }
    const staggers = Array.from(document.querySelectorAll('.js-stagger'))
    staggers.forEach((st) => {
        const stg_app = new Stagger(st)
        stg_app.init()
    })  
    const lend_wrap = document.querySelector('.lending_simulator')
    if (lend_wrap) {
        ReactDOM.render(<LendingSim tax={lend_wrap.dataset.tax}/>, lend_wrap)
    }     
    Menu()
    const carousels = document.querySelectorAll('.js-carousel')
    carousels.forEach((c)  => {
        if (c) {
            const c_length = Array.from(c.querySelectorAll('.image')).length
            const prev = c.querySelector('.prev')
            const next = c.querySelector('.next')
            const mySiema = new Siema({
                selector: c.querySelector('.main-carousel'),
                duration: 250,
                easing: 'ease-out',
                perPage: 1,
                startIndex: 0,
                draggable: window.innerWidth > 640 ? false : true,
                multipleDrag: false,
                threshold: 20,
                loop: true,
                rtl: false,
                onInit: () => {},
                onChange: () => {showCurrentSlide()},
            });
            prev && prev.addEventListener('click', () => {
                mySiema.prev()
            })  
            next && next.addEventListener('click', () => {
                mySiema.next()
            }) 
            function showCurrentSlide() {
                const counter = document.querySelector('.counter_text')
                counter.innerHTML = `${mySiema.currentSlide + 1}/${c_length}`
            }   
        }        
    })
})