const Menu = () => {
    const icon = document.querySelector('.js-menu-icon')
    icon && icon.addEventListener('click', () => {
        toggleMenu()
    })
    const toggleMenu = () => {
        const menu = document.querySelector('.js-menu')
        if (menu) {
            menu.classList.toggle('open')
            const links = Array.from(menu.querySelectorAll('a'))
            links.forEach(a => a.classList.toggle('active'))
        } 
    }
}
export default Menu